import { useQuery } from '@tanstack/vue-query';
import { fetchUserTokens } from '../api/requests';

export const createUserTokensQuery = (userId?: string | null) => ({
  queryKey: ['users', userId, 'tokens'],
  queryFn: async () => {
    if (!userId) {
      return [];
    }
    const { tokens } = await fetchUserTokens(userId);
    return tokens ?? [];
  },
  placeholderData: [],
  staleTime: 1000 * 60 * 60 * 1, // 1 hour
});

export function useUserTokensQuery(userId?: string | null) {
  return useQuery(createUserTokensQuery(userId));
}

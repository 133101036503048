import type { CartItem } from '@tn/shared';

interface FetchUserCartResponse {
  cart: CartItem[];
}

export async function fetchUserCart(userId: string) {
  const { headers } = authenticatedRequestHeaders();

  return await $fetch<FetchUserCartResponse>(`/api/users/${userId}/cart`, {
    headers,
  });
}

export async function addItemToCart(uid: string, item: CartItem) {
  return await $fetch(`/api/users/${uid}/cart`, {
    method: 'POST',
    body: {
      ...item,
    },
  });
}

export async function removeItemFromCart(uid: string, productId: string) {
  return await $fetch(`/api/users/${uid}/cart`, {
    method: 'DELETE',
    body: {
      productId,
    },
  });
}

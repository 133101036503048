import type { LoginProvider, UserDataResponse } from '@tn/shared';

export async function fetchCurrentUser() {
  const { headers } = authenticatedRequestHeaders();

  return await $fetch<UserDataResponse>('/api/me', {
    headers,
  });
}

export async function updateLastSeenRenewBanner(uid: string) {
  return await $fetch(`/api/users/${uid}/last-seen-renew-banner`, {
    method: 'PATCH',
  });
}

export async function updateLoginProviders(
  uid: string,
  provider: LoginProvider
) {
  return await $fetch(`/api/users/${uid}/providers`, {
    method: 'PATCH',
    body: {
      provider,
    },
  });
}

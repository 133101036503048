import { useQuery } from '@tanstack/vue-query';
import { fetchUserOrders } from '../api/requests';

export const createUserOrdersQuery = (userId?: string | null) => ({
  queryKey: ['users', userId, 'orders'],
  queryFn: async () => {
    if (!userId) {
      return [];
    }

    const { orders } = await fetchUserOrders(userId);

    return orders ?? [];
  },
  placeholderData: [],
  staleTime: 1000 * 60 * 60 * 1, // 1 hour
});

export function useUserOrdersQuery(userId?: string | null) {
  return useQuery(createUserOrdersQuery(userId));
}

import type { Cycle } from '@tn/shared';

export const getCycleByCycleNumber = (cycles: Cycle[], cycleNumber: number) => {
  return cycles.find((cycle) => cycle.cycleNumber === cycleNumber);
};

export const getCycleFromMsTimestamp = (
  cycles: Cycle[],
  msTimestamp: number
) => {
  return (
    cycles.find(
      (cycle) => cycle.startDate <= msTimestamp && cycle.endDate >= msTimestamp
    ) || null
  );
};

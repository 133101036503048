import { useStorage } from '@vueuse/core';

export const useOnboardingStore = defineStore('onboarding', {
  state: () => ({
    onboardingData: useStorage(
      'onboardingProfileData',
      {} as { [key: string]: string | string[] }
    ),
  }),

  hydrate(storeState) {
    // @ts-expect-error: https://github.com/microsoft/TypeScript/issues/43826
    storeState.onboardingData = useStorage(
      'onboardingProfileData',
      {} as { [key: string]: string | string[] }
    );
  },

  actions: {
    saveOnboardingStepData(stepData: { [key: string]: string | string[] }) {
      const existingData = this.onboardingData || {};

      this.onboardingData = { ...existingData, ...stepData };
    },

    removeOnboardingStepData(stepKey: string) {
      const existingData = this.onboardingData || {};

      if (stepKey in existingData) {
        delete existingData[stepKey];
        this.onboardingData = { ...existingData };
      }
    },

    resetOnboardingData() {
      this.onboardingData = {};
    },
  },
});

import { useQuery } from '@tanstack/vue-query';
import { fetchUserCart } from '../api/requests';

export const createUserCartQuery = (userId?: string | null) => ({
  queryKey: ['users', userId, 'cart'],
  queryFn: async () => {
    if (!userId) {
      return [];
    }
    const { cart } = await fetchUserCart(userId);
    return cart ?? [];
  },
  placeholderData: [],
  staleTime: 1000 * 60 * 60 * 1, // 1 hour
});

export function useUserCartQuery(userId?: string | null) {
  return useQuery(createUserCartQuery(userId));
}

<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: Date,
    required: false,
    default: undefined,
  },
  disabledDates: {
    type: [Array, Object, Function] as PropType<any | null>,
    default: null,
  },
  icon: {
    type: String,
    required: false,
    default: 'i-heroicons-calendar-days-20-solid',
  },
  mode: {
    type: String,
    required: false,
    default: 'date',
  },
});

const emit = defineEmits(['update:modelValue', 'update:model-value', 'close']);

const date = computed({
  get: () => props.modelValue ?? undefined,
  set: (value) => {
    emit('update:model-value', value);
    emit('close');
  },
});
</script>

<template>
  <UPopover :popper="{ placement: 'bottom-start' }">
    <slot />
    <template #panel="{ close }">
      <DatePickerBase
        v-model="date"
        :mode="mode"
        :disabled-dates="disabledDates"
        @close="close"
      />
    </template>
  </UPopover>
</template>

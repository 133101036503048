import type { BrandStatus } from '@tn/shared';

export const useBrandStatus = () => {
  const { brandUser } = useUserState();

  const getBrandStatus = async () => {
    const brandStatus: BrandStatus | undefined = brandUser?.value?.brand?.id
      ? await $fetch(`/api/brands/${brandUser.value.brand.id}/status`, {
          method: 'GET',
          ...authenticatedRequestHeaders(),
        })
      : undefined;

    return brandStatus;
  };

  return { getBrandStatus };
};

import { getCycleByCycleNumber } from '../helpers';
import { useCyclesQuery } from '../queries';

export function useCyclesData() {
  const { data, isLoading, error } = useCyclesQuery();
  const now = useNow();

  const cycles = computed(() => data.value ?? []);

  const currentCycle = computed(() =>
    cycles.value.find(
      (cycle) => cycle.startDate <= now.value && cycle.endDate >= now.value
    )
  );

  const futureCycles = computed(() => {
    const currentStartDate = currentCycle.value?.startDate;

    if (!currentStartDate || !cycles.value.length) {
      return [];
    }

    return cycles.value.filter((cycle) => cycle.startDate > currentStartDate);
  });

  const nextCycle = computed(() =>
    currentCycle.value && cycles.value.length > 0
      ? getCycleByCycleNumber(cycles.value, currentCycle.value.cycleNumber + 1)
      : null
  );

  const previousCycle = computed(() =>
    currentCycle.value && cycles.value.length > 0
      ? getCycleByCycleNumber(cycles.value, currentCycle.value.cycleNumber - 1)
      : null
  );

  return {
    cycles,
    futureCycles,
    nextCycle,
    previousCycle,
    currentCycle,
    isLoading,
    error,
  };
}

import { useFetch } from '#app';
import type { RemoteConfig } from '@tn/shared';

export async function fetchRemoteConfig() {
  const { data, error } = await useFetch<RemoteConfig>('/api/remote-config');

  if (error.value) {
    console.error('Failed to fetch remote config:', error.value);
    return null;
  }

  return data.value;
}

export async function setRemoteConfig() {
  const config = useState<RemoteConfig>('remote-config');
  const data = await fetchRemoteConfig();

  if (data) {
    config.value = data;
  }
}

<script setup lang="ts">
import { DatePicker as VCalendarDatePicker } from 'v-calendar';
import type { DatePickerDate } from 'v-calendar/dist/types/src/use/datePicker.js';
import type { PropType } from 'vue';
import 'v-calendar/dist/style.css';

const props = defineProps({
  modelValue: {
    type: [Date, Object] as PropType<DatePickerDate | null>,
    default: null,
  },
  disabledDates: {
    type: [Array, Object, Function] as PropType<any | null>,
    default: null,
  },
});

const emit = defineEmits(['update:model-value', 'close']);

const date = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:model-value', value);
    emit('close');
  },
});

const attrs = {
  transparent: true,
  borderless: true,
  color: 'primary',
  'first-day-of-week': 2,
};
</script>

<template>
  <VCalendarDatePicker
    v-model="date"
    :disabled-dates="props.disabledDates"
    v-bind="{ ...attrs, ...$attrs }"
    is-required
    @dayclick="
      (_, event) => {
        // https://github.com/nuxt/ui/issues/2208
        event.target.blur();
      }
    "
  />
</template>

<style>
.vc-primary {
  --vc-accent-50: rgba(204, 245, 255, 1);
  --vc-accent-100: rgba(157, 223, 255, 1);
  --vc-accent-200: rgba(128, 213, 255, 1);
  --vc-accent-300: rgba(92, 168, 234, 1);
  --vc-accent-400: rgba(64, 149, 218, 1);
  --vc-accent-500: rgba(0, 129, 199, 1);
  --vc-accent-600: rgba(0, 114, 181, 1);
  --vc-accent-700: rgba(0, 94, 156, 1);
  --vc-accent-800: rgba(0, 75, 131, 1);
  --vc-accent-900: rgba(0, 54, 105, 1);
}
</style>

const useIdentifyEvent = (
  userId: string,
  userTraits: { [key: string]: string | number | boolean | string[] } = {}
) => {
  if (!import.meta.client) {
    return;
  }

  const { $identifyEvent } = useNuxtApp();

  $identifyEvent(userId, {
    ...userTraits,
  });
};

export default useIdentifyEvent;

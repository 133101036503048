import { useCurrentUserQuery } from '~/features/auth';

export const useUserState = () => {
  const { data, refetch } = useCurrentUserQuery();

  const refresh = async () => {
    await refetch();
    identifyUser();

    if (data.value?.isBrand) {
      const brandSubscriptionStore = useBrandSubscriptionStore();
      await brandSubscriptionStore.fetchCurrentSubscription();
    }

    return data.value;
  };

  const identifyUser = () => {
    if (!data.value?.uid) return;

    const membership = data.value?.memberUser?.membership;
    const userType = data.value?.userType;

    const userTraits = {
      user_type: userType,
      ...(membership != null && { member_status: membership }),
    };

    useIdentifyEvent(data.value?.uid, userTraits);
  };

  return {
    uid: computed(() => data.value?.uid),
    email: computed(() => data.value?.email),
    isEmailVerified: computed(() => data.value?.isEmailVerified),
    hasCompletedOnboarding: computed(() => data.value?.hasCompletedOnboarding),
    testClockTimeMs: computed(() => data.value?.testClockTimeMs),
    lastSeenRenewBannerMs: computed(() => data.value?.lastSeenRenewBannerMs),
    isCmUser: computed(() => data.value?.isCmUser),
    isPremium: computed(() => data.value?.isPremium),
    userType: computed(() => data.value?.userType),
    isBrand: computed(() => data.value?.isBrand),
    memberUser: computed(() => data.value?.memberUser),
    brandUser: computed(() => data.value?.brandUser),
    refresh,
  };
};

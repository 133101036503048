import { useQuery } from '@tanstack/vue-query';
import { fetchUserReviews } from '../api';

export const createUserReviewsQuery = (userId?: string | null) => ({
  queryKey: ['users', userId, 'reviews'],
  queryFn: async () => {
    if (!userId) {
      return [];
    }
    const { reviews } = await fetchUserReviews(userId);
    return reviews ?? [];
  },
  placeholderData: [],
  staleTime: 1000 * 60 * 60 * 1, // 1 hour
});

export function useUserReviewsQuery(userId?: string | null) {
  return useQuery(createUserReviewsQuery(userId));
}

import * as Sentry from '@sentry/vue';

export default defineNuxtRouteMiddleware(async (to) => {
  const {
    uid,
    email,
    isEmailVerified,
    userType,
    isBrand,
    hasCompletedOnboarding,
  } = useUserState();
  const brandStatusComposable = useBrandStatus();

  const requiresAuth = to.meta.requiresAuth ?? true;
  let isBrandOnboardingCompleted = false;

  const brandStatus = await brandStatusComposable?.getBrandStatus();

  isBrandOnboardingCompleted = !!brandStatus?.onboarding?.completedAtTimeMs;

  if (email.value && uid.value) {
    Sentry.setUser({
      email: email.value,
      id: uid.value,
      user_type: userType.value,
    });
  } else {
    Sentry.setUser(null);
  }

  // Skip middleware for API requests
  if (to.path.startsWith('/api/')) {
    return;
  }

  if (!requiresAuth) {
    return;
  }

  if (
    to.name &&
    to.name.toString().startsWith('brands-onboarding') &&
    uid.value &&
    isBrand.value
  ) {
    return;
  }

  // Determine what the homepage route would be based on user type
  if (to.path === '/') {
    return navigateTo({
      name: isBrand.value ? 'brands-dashboard' : 'home',
    });
  }

  // Prevent regular members from viewing the brands pages
  if (to.path.startsWith('/brands') && uid.value && !isBrand.value) {
    return navigateTo({
      name: 'home',
    });
  }

  if (isBrand.value) {
    if (!isEmailVerified.value) {
      return navigateTo({
        name: 'brands-onboarding-email-verification',
      });
    }

    if (!isBrandOnboardingCompleted) {
      return navigateTo({
        name: 'brands-onboarding-step',
        params: { id: 'details' },
      });
    }
  }

  const isOnboarding = to.fullPath.includes('onboarding');
  const isLogin = String(to.name).startsWith('login');
  const isBrandSignup = String(to.name) === 'brands-signup';

  // redirect the user to the login page if not authenticated
  if (
    !uid.value &&
    !isLogin &&
    to.name !== 'member-reset-password' &&
    !isOnboarding &&
    !isBrandSignup
  ) {
    return navigateTo({
      name: 'login',
      query: {
        ...to.query,
        ...(to.fullPath !== '/' &&
          to.fullPath !== '/members' && {
            redirect: to.fullPath,
          }),
      },
    });
  }
  // redirect the user to the homepage if authenticated
  if (uid.value) {
    if (to.name === 'onboarding-check-email') {
      return;
    }

    if (!hasCompletedOnboarding.value && !isOnboarding && !isBrand.value) {
      return navigateTo({
        name: 'member-onboarding-start',
      });
    }

    if (hasCompletedOnboarding.value && isOnboarding) {
      return navigateTo({
        name: 'home',
      });
    }

    if (isLogin && isBrand.value && isBrandOnboardingCompleted) {
      return navigateTo({
        name: 'brands-dashboard',
        query: to.query,
      });
    }

    if (isLogin) {
      return navigateTo({
        name: isBrand.value ? 'brands-dashboard' : 'home',
        query: to.query,
      });
    }
  }
});

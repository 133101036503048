import { defineNuxtPlugin, useRuntimeConfig } from '#app';
import isFunction from 'lodash/isFunction';
import { reactive } from 'vue';

export enum CookieProCategoryId {
  StrictlyNecessary = 'C0001',
  Performance = 'C0002',
  Functional = 'C0003',
  Targeting = 'C0004',
  SocialMedia = 'C0005',
}

export interface CookieProClient {
  cookiesState: { [categoryId in CookieProCategoryId]: boolean };
  getActiveCookieCategories: () => CookieProCategoryId[];
  isCookieCategoryActive: (categoryId: CookieProCategoryId) => boolean;
  isAlertBoxClosed: () => boolean;
  showCookiePreferences: () => void;
  onConsentUpdated: (
    callback: (activeCategories?: CookieProCategoryId[]) => void
  ) => void;
}

declare global {
  interface Window {
    OptanonWrapper?: () => void;
    OneTrust?: {
      IsAlertBoxClosed: () => boolean;
      ToggleInfoDisplay: () => void;
    };
    OnetrustActiveGroups?: string;
  }
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  const injectCookieproSDK = (scriptId: string) => {
    const script = document.createElement('script');
    script.setAttribute('id', 'cookiepro-sdk');
    script.setAttribute(
      'src',
      'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js'
    );
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('charset', 'UTF-8');
    script.setAttribute('data-domain-script', scriptId);
    script.setAttribute('async', '');
    document.head.appendChild(script as HTMLHeadElement);
  };

  const cookiesState: Record<CookieProCategoryId, boolean> = reactive({
    [CookieProCategoryId.StrictlyNecessary]: true,
    [CookieProCategoryId.Performance]: false,
    [CookieProCategoryId.Functional]: false,
    [CookieProCategoryId.Targeting]: false,
    [CookieProCategoryId.SocialMedia]: false,
  });

  const onConsentUpdatedCallbacks: ((
    activeCategories?: CookieProCategoryId[]
  ) => void)[] = [];

  const getActiveCookieCategories = (): CookieProCategoryId[] =>
    (window?.OnetrustActiveGroups?.split(',').filter((groupId) => !!groupId) ||
      []) as CookieProCategoryId[];

  const isCookieCategoryActive = (categoryId: CookieProCategoryId): boolean => {
    const activeGroups = getActiveCookieCategories();
    return activeGroups.includes(categoryId);
  };

  const onConsentUpdated = (
    callback: (activeCategories?: CookieProCategoryId[]) => void
  ) => {
    onConsentUpdatedCallbacks.push(callback);
  };

  const showCookiePreferences = () => {
    window?.OneTrust?.ToggleInfoDisplay();
  };

  const trackingCookiesSideEffects: {
    [categoryId: string]: (isActive: boolean) => void;
  } = {};

  const applyTrackingCookiesSideEffects = () => {
    Object.keys(trackingCookiesSideEffects).forEach((categoryId) => {
      trackingCookiesSideEffects[categoryId](
        isCookieCategoryActive(categoryId as CookieProCategoryId)
      );
    });
  };

  const cookiePro: CookieProClient = {
    cookiesState,
    getActiveCookieCategories,
    isCookieCategoryActive,
    showCookiePreferences,
    isAlertBoxClosed: () => window?.OneTrust?.IsAlertBoxClosed() ?? false,
    onConsentUpdated,
  };

  window.OptanonWrapper = () => {
    const cookieCategories = Object.values(CookieProCategoryId);
    cookieCategories.forEach((categoryId) => {
      cookiesState[categoryId] = isCookieCategoryActive(categoryId);
    });

    const activeGroups = getActiveCookieCategories();
    onConsentUpdatedCallbacks.forEach(
      (callback) => isFunction(callback) && callback(activeGroups)
    );

    applyTrackingCookiesSideEffects();
  };

  injectCookieproSDK(config.public.cookieProScriptId);

  return {
    provide: {
      cookiePro: cookiePro,
    },
  };
});

<script setup lang="ts">
import cloneDeep from 'lodash/cloneDeep';
import { format, subDays, endOfMonth, addDays } from 'date-fns';
import { useResetTestClock, useUpdateTestClock } from '~/features/test-clock';

const notify = useNotifications();
const {
  uid,
  email,
  isCmUser,
  isPremium,
  isEmailVerified,
  hasCompletedOnboarding,
  testClockTimeMs,
} = useUserState();
const { updateTestClock, isUpdating: isSaving } = useUpdateTestClock();
const { resetTestClock, isUpdating: isResetting } = useResetTestClock();
const route = useRoute();
const isModalOpen = ref(false);

const isLoggedIn = computed(() => Boolean(uid.value));

const DEFAULT_DATE = computed(() =>
  testClockTimeMs.value ? new Date(testClockTimeMs.value) : new Date()
);

const DEFAULT_USER_STATE = computed(() => [
  {
    key: 'isCmUser',
    label: 'CM User',
    active: isCmUser.value,
  },
  {
    key: 'isPremium',
    label: 'Premium',
    active: isPremium.value,
  },
  {
    key: 'hasCompletedOnboarding',
    label: 'Onboarding',
    active: hasCompletedOnboarding.value,
  },
  {
    key: 'isEmailVerified',
    label: 'Email Verified',
    active: isEmailVerified.value,
  },
]);

const currentDate = ref(DEFAULT_DATE.value);
const localDate = ref(DEFAULT_DATE.value);
const activeState = ref(cloneDeep(DEFAULT_USER_STATE.value));
const localState = ref(cloneDeep(DEFAULT_USER_STATE.value));

watch(
  () => DEFAULT_DATE.value,
  (newDate) => {
    currentDate.value = newDate;
    localDate.value = newDate;
  }
);

watch(
  () => DEFAULT_USER_STATE.value,
  (newState) => {
    activeState.value = cloneDeep(newState);
    localState.value = cloneDeep(newState);
  },
  { deep: true }
);

const getYesterday = () => subDays(currentDate.value, 1);

const getNextMonthStart = () => {
  const endOfCurrentMonth = endOfMonth(currentDate.value);
  return addDays(endOfCurrentMonth, 2);
};

const onCloseModal = () => {
  isModalOpen.value = false;
  localDate.value = DEFAULT_DATE.value;
  localState.value = cloneDeep(DEFAULT_USER_STATE.value);
};

const onSaveChanges = async () => {
  const isCmUser = localState.value.find((item) => item.key === 'isCmUser');
  const isPremium = localState.value.find((item) => item.key === 'isPremium');
  const isEmailVerified = localState.value.find(
    (item) => item.key === 'isEmailVerified'
  );
  const hasCompletedOnboarding = localState.value.find(
    (item) => item.key === 'hasCompletedOnboarding'
  );

  const localDateValue = new Date(localDate.value);
  await updateTestClock({
    testClock: localDateValue,
    isCmUser: !!isCmUser?.active,
    isPremium: !!isPremium?.active,
    isEmailVerified: !!isEmailVerified?.active,
    hasCompletedOnboarding: !!hasCompletedOnboarding?.active,
  });
  isModalOpen.value = false;
};

const isOnMembersPage = computed(() => {
  return route.path.startsWith('/members');
});

const onResetState = async () => {
  try {
    await resetTestClock();
    notify.showSuccessNotification({
      description: 'User state reset successfully.',
    });
    localDate.value = new Date();
    currentDate.value = new Date();
    isModalOpen.value = false;
  } catch (e) {
    console.error(e);
    notify.showErrorNotification({
      description: 'Something went wrong.',
    });
  }
};
</script>

<template>
  <div v-if="isLoggedIn && isOnMembersPage" class="fixed bottom-0 right-0 z-20">
    <UPopover :popper="{ placement: 'top-end' }">
      <button
        class="font-poppins relative m-2 flex h-14 w-14 items-center justify-center rounded-full bg-[#042425] text-center text-xl font-bold text-[#F8A621]"
      >
        <span
          v-if="testClockTimeMs"
          class="font-poppins absolute right-[8px] top-[12px] inline-flex h-[8px] w-[8px] items-center justify-center rounded-full bg-red-400 text-xs font-medium text-white"
        ></span>
        TN
      </button>
      <UModal
        v-model="isModalOpen"
        :ui="{
          background: 'bg-transparent',
          shadow: 'shadow-none',
        }"
        @close="isModalOpen = false"
      >
        <div
          class="font-poppins text-tnNeutral-800 rounded-[20px] border-2 border-b-4 border-black bg-white px-4 pt-10 text-center"
        >
          <h3 class="font-roca mb-2 text-2xl font-bold">Testing Playground</h3>
          <p class="text-tnNeutral-600 text-lg leading-tight">
            Update the current state for user:
            <strong class="text-tnNeutral-900 font-medium">{{ email }}</strong>
          </p>

          <div class="mx-auto mt-6 flex items-center justify-center space-x-2">
            <TnDatePicker
              v-model="localDate"
              :disabled-dates="[
                { start: new Date(0), end: getYesterday() },
                { start: getNextMonthStart(), end: null },
              ]"
            >
              <UButton
                color="secondary"
                icon="i-heroicons-calendar-days"
                :label="format(localDate, 'MMMM d, yyy')"
              />
            </TnDatePicker>

            <TnDatePicker v-model="localDate" mode="time">
              <UButton
                color="secondary"
                icon="i-heroicons-clock"
                :label="format(localDate, 'h:mm aa')"
              />
            </TnDatePicker>
          </div>

          <div class="mx-auto mt-6 grid max-w-[350px] grid-cols-2">
            <UCheckbox
              v-for="s in localState"
              :key="s.key"
              v-model="s.active"
              :name="s.key"
              :label="s.label"
            />
          </div>

          <div
            class="mt-6 flex items-center justify-between space-x-2 border-t py-4"
          >
            <div>
              <UButton
                v-if="testClockTimeMs"
                variant="link"
                class="text-sm font-medium"
                :loading="isResetting"
                :disabled="isSaving"
                color="red"
                @click="onResetState"
              >
                Reset State
              </UButton>
            </div>
            <div class="flex items-center justify-end space-x-2">
              <UButton
                color="secondary"
                :disabled="isResetting"
                @click="onCloseModal"
                >Close</UButton
              >
              <UButton
                color="primary"
                :loading="isSaving"
                :disabled="isResetting"
                @click="onSaveChanges"
                >Save Changes</UButton
              >
            </div>
          </div>
        </div>
      </UModal>

      <template #panel>
        <div class="font-poppins text-tnNeutral-800 text-sm">
          <div class="p-4">
            <p class="text-xs font-medium leading-none text-gray-400">
              Current Date
            </p>
            <h3 class="mb-1 flex items-center text-lg font-bold">
              <span>{{ format(currentDate, 'MMMM d, yyy') }}</span>
              <button @click="isModalOpen = true">
                <UIcon
                  name="i-heroicons-pencil"
                  class="ml-1 h-4 w-4 text-gray-500"
                />
              </button>
            </h3>
            <span
              class="block rounded-lg p-1 text-center text-xs font-medium"
              :class="{
                'bg-green-50 text-green-700': !testClockTimeMs,
                'bg-red-50 text-red-700': testClockTimeMs,
              }"
            >
              {{ testClockTimeMs ? 'Active Test State' : 'Current User State' }}
            </span>
            <div class="mt-4 grid grid-cols-2 gap-2">
              <div
                v-for="s in activeState"
                :key="s.key"
                class="flex items-center space-x-1"
              >
                <UIcon
                  v-if="s.active"
                  name="i-heroicons-check"
                  class="h-5 w-5 text-green-500"
                />
                <UIcon
                  v-else
                  name="i-heroicons-x-mark"
                  class="h-5 w-5 text-red-700"
                />

                <span>{{ s.label }}</span>
              </div>
            </div>
            <div class="mt-4 text-center">
              <UButton color="primary" @click="isModalOpen = true"
                >Update State</UButton
              >
            </div>
          </div>
          <p
            class="border-t border-dashed border-gray-300 bg-gray-50 p-2 text-center text-xs"
          >
            Logged in as:<br /><span class="font-medium">{{ email }}</span>
          </p>
        </div>
      </template>
    </UPopover>
  </div>
</template>

import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { resetTestClock } from '../api';
import type { Token } from '@tn/shared';

export const useResetTestClock = () => {
  const queryClient = useQueryClient();
  const { uid } = useUserState();
  const mutation = useMutation({
    mutationFn: async () => resetTestClock(),
    onMutate: async () => {
      // Cancel outgoing refetches
      await queryClient.cancelQueries({
        queryKey: ['users', uid.value, 'tokens'],
      });

      // Snapshot previous value
      const previousTokens = queryClient.getQueryData([
        'users',
        uid.value,
        'tokens',
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(['users', uid.value, 'tokens'], (old: Token[]) =>
        old?.filter((token) => !token.isFromTestClock)
      );

      return { previousTokens };
    },
    onError: (err, variables, context) => {
      // If mutation fails, roll back
      if (context?.previousTokens) {
        queryClient.setQueryData(
          ['users', uid.value, 'tokens'],
          context.previousTokens
        );
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['me'],
        exact: true,
      });
    },
  });

  return {
    resetTestClock: mutation.mutateAsync,
    isUpdating: mutation.isPending,
    error: mutation.error,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  };
};

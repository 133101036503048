import type { Order } from '@tn/shared';

interface FetchUserOrdersResponse {
  orders: Order[];
}

export async function fetchUserOrders(userId: string) {
  const { headers } = authenticatedRequestHeaders();

  return await $fetch<FetchUserOrdersResponse>(`/api/users/${userId}/orders`, {
    headers,
  });
}

import { useQuery } from '@tanstack/vue-query';
import { fetchCycles } from '../api/requests';

export const createCyclesQuery = () => ({
  queryKey: ['cycles'],
  queryFn: () => fetchCycles(),
  placeholderData: [],
  staleTime: 1000 * 60 * 60 * 24, // 24 hours
});

export function useCyclesQuery() {
  return useQuery(createCyclesQuery());
}

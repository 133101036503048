<script setup lang="ts">
import { setRemoteConfig } from '~/utils/fetchRemoteConfig';
import { VueQueryDevtools } from '@tanstack/vue-query-devtools';

const config = useRuntimeConfig();
const { refresh, uid } = useUserState();
const auth = useFirebaseAuth();

useTriggerOperations(uid.value);

let unsubscribe: (() => void) | undefined;

onMounted(() => {
  unsubscribe = auth?.onAuthStateChanged((user) => {
    if (user && uid.value !== user.uid) {
      refresh();
      setRemoteConfig();
    }
  });
});

onUnmounted(() => {
  unsubscribe?.();
});

const shouldShowDevTools = computed(
  () => config.public.appEnv !== 'production'
);
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
    <UNotifications />
    <UModals />
  </NuxtLayout>
  <TnDevTools v-if="shouldShowDevTools" />
  <VueQueryDevtools button-position="bottom-left" />
</template>

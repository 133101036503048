import { useFirestore, useDocument } from 'vuefire';
import { doc } from 'firebase/firestore';
import { watch } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';

export function useTriggerOperations(userId?: string | null) {
  if (!userId) {
    return;
  }

  const queryClient = useQueryClient();
  const db = useFirestore();
  const triggerDoc = useDocument(doc(db, 'triggerOperations', userId));

  // Watch for changes to tokens.create status
  const stopWatch = watch(
    () => triggerDoc.value?.['tokens.create']?.status,
    (newStatus) => {
      if (newStatus === 'completed') {
        // Invalidate the local cache to force a refresh
        queryClient.invalidateQueries({
          queryKey: ['users', userId, 'tokens'],
          exact: true,
        });
      }
    }
  );

  // Return cleanup function
  return stopWatch;
}

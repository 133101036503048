import * as Sentry from '@sentry/vue';
import { useQueryClient } from '@tanstack/vue-query';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const {
    public: { sentry },
  } = useRuntimeConfig();

  if (!sentry.dsn) {
    return;
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    tunnel: sentry.tunnel,
    enabled: sentry.environment !== 'local',
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        /** Compression disabled to fix this issue
         * @see: https://github.com/getsentry/sentry-javascript/issues/7302
         */
        useCompression: false,
      }),
    ],
    tracesSampleRate: sentry.tracesSampleRate,
    tracePropagationTargets: [
      'localhost',
      'us-central1-tn-dev-e8c9a.cloudfunctions.net',
      'us-central1-tn-prod-dea2c.cloudfunctions.net',
    ],
    replaysSessionSampleRate: sentry.replaySessionsSampleRate,
    replaysOnErrorSampleRate: sentry.replayErrorsSampleRate,
    beforeSend(event, hint) {
      const error = hint.originalException as any;

      // Drop this event, don't send it to Sentry
      if (error?.data?.code === 'auth/invalid-credential') {
        return null;
      }

      return event;
    },
  });

  // Update defaults in vue-query to capture errors in Sentry
  queryClient.setDefaultOptions({
    mutations: {
      onError: (error) => {
        console.error('Failed to run mutation:', error);
        Sentry.captureException(error);
      },
    },
  });

  return {
    provide: {
      sentry: Sentry,
    },
  };
});

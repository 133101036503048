import { ref, watch, computed } from 'vue';
import { useUserState } from './useUserState';
import { useThrottleFn, useRafFn } from '@vueuse/core';

const useNow = () => {
  const { testClockTimeMs } = useUserState();
  const systemNow = ref(Date.now());
  const isClient = process.client;

  const update = useThrottleFn(() => {
    systemNow.value = Date.now();
  }, 60000);

  const now = computed(() => {
    return testClockTimeMs.value ?? systemNow.value;
  });

  if (isClient) {
    useRafFn(update, { immediate: true });
  }

  watch(
    testClockTimeMs,
    (newValue) => {
      if (newValue === null && isClient) {
        useRafFn(update, { immediate: true });
      }
    },
    { immediate: true }
  );

  return now;
};

export default useNow;

<script setup lang="ts">
import IconDiamond from '@/assets/icons/diamond-orange.svg?skipsvgo';

const modal = useModal();
</script>

<template>
  <UModal
    :ui="{
      width: 'w-full sm:max-w-[640px]',
      background: 'bg-transparent',
      shadow: 'shadow-none',
    }"
  >
    <div
      class="bg-beige-400 relative rounded-[20px] border-2 border-b-4 border-black px-4 py-6 text-center"
    >
      <span
        class="absolute left-[-5px] top-[-15px] block h-[60px] w-[60px] sm:left-[15px] sm:top-[35px]"
        ><IconDiamond class="h-full w-full"
      /></span>
      <span
        class="absolute bottom-[10px] right-[10px] block h-[32px] w-[32px] sm:bottom-[100px] sm:right-[20px]"
        ><IconDiamond class="h-full w-full"
      /></span>
      <div class="mx-auto my-6 flex max-w-[400px] flex-col">
        <h3
          class="font-roca text-tnNeutral-800 mb-4 text-2xl font-bold text-opacity-80"
        >
          You need to upgrade to access these features
        </h3>
        <p class="text-tnNeutral-600 mb-6 text-center text-lg">
          Visit the Subscription page under Settings to manage your access.
        </p>
        <NuxtLink to="/brands/settings" @click="modal.close()">
          <UButton color="primary" block>Manage Subscription</UButton>
        </NuxtLink>
      </div>
    </div>
  </UModal>
</template>

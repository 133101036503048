export default {
  brands: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/brands.vue"),
  default: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/default.vue"),
  "green-shades": () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/green-shades.vue"),
  green: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/green.vue"),
  "onboarding-base": () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/onboarding-base.vue"),
  product: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/product.vue"),
  "products-base": () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/products-base.vue"),
  promotions: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/promotions.vue"),
  "review-base": () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/review-base.vue"),
  sky: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/sky.vue")
}